/* css del index */
.root {
    align-items: flex-start;
    background-image: url('../assets/mainBackground.jpg');
    background-position: top;
    background-size: cover;
    height: 800px;
    margin-top: 0;
  }

/* css del Navbar */
.rootNavbar {
  flex-grow: 1;
  margin-bottom: 0;
}
.barra {
  background: #FFCC00;
  color: black;
}
.navLink {
  text-decoration: none;
  color: black;
}

/* css del Dashboard */
.rootDashboard {
  background: #2e2e2e;
  background: -moz-linear-gradient(top, #2e2e2e 0%, #ffcc00 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2e2e2e), color-stop(100%, #ffcc00));
  background: -webkit-linear-gradient(top, #2e2e2e 0%, #ffcc00 100%);
  background: -o-linear-gradient(top, #2e2e2e 0%, #ffcc00 100%);
  background: -ms-linear-gradient(top, #2e2e2e 0%, #ffcc00 100%);
  background: linear-gradient(to bottom, #2e2e2e 0%, #ffcc00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2e2e', endColorstr='#ffcc00', GradientType=0 );
}